import React, { Component } from 'react';
import { connect } from 'react-redux';
import { integrationHasCompleted, integrationInitiateOnboarding } from '../../actions';
import Spinner from '../../global/Spinner';
import DayPickerInput from '../../global/inputs/DayPickerInput';
import Icon from '../../global/Icon';
import { isWebView, Constants } from '../../global/Helpers';
export class IntegrationPage extends Component {
    constructor(props) {
        super(props);
        this.props.integrationHasCompleted();
        this.state = {
            partner: '',
            integrationMode: '',
            invoicesDate: '',
            onboardingLink: '',
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.organisation && prevProps.organisation && this.props.organisation.name !== prevProps.organisation.name) {
            this.setState({
                partner: '',
                integrationMode: '',
                invoicesDate: '',
                onboardingLink: '',
            });
            this.props.integrationHasCompleted();
        }
    }
    selectIntegration(partner, event) {
        event.preventDefault();
        this.setState({
            partner: partner,
        }, () => {
            this.initiateOnboarding();
        });
    }

    selectIntegrationMode(mode, event) {
        event.preventDefault();
        this.setState({
            integrationMode: mode,
        }, () => {
            this.initiateOnboarding();
        });
    }

    selectInvoicesDate(state) {
        this.setState({
            invoicesDate: state.selectedDay,
        }, () => {
            this.initiateOnboarding();
        });
    }

    initiateOnboarding() {
        if(this.state.partner.length === 0 || this.state.integrationMode.length === 0 || !this.state.invoicesDate)
        {
            return;
        }

        this.props.integrationInitiateOnboarding({ partner: this.state.partner, integrationMode: this.state.integrationMode, invoicesDate: this.state.invoicesDate });
    }

    getFormattedOnboardingUrl() {
        if(isWebView()) {
            return this.props.integration.onboardingLink + Constants.webkitTargetNewWebViewUrlHash();
        }
        
        return this.props.integration.onboardingLink;
    }

    render() {
        return (
            <div className="content__body integration">
                <div className="content__main">
                    <div className="content__wrapper content__wrapper--stuffing">
                        {this.props.integration.loading ? (
                            <div className="content__spinner">
                                <Spinner />
                            </div>
                        ) : this.props.integration.completed ? (
                            <div>
                                <h1 className="title title--h1">Integration med ditt affärssystem</h1>
                                <h3>Anslutning mellan Factoringgruppen och {this.props.integration.partner}</h3>
                                {this.props.integration.autoPilot ? (
                                    <p className="paragraph">
                                        Fakturor som bokförs men ej är skickade till kund kommer löpande att hämtas från ert affärssystem och behandlas av
                                        Factoringgruppen per automatik eftersom du valt "Autopilot". 
                                    </p>
                                ) : (
                                    <>
                                        <p className="paragraph">
                                            Fakturorna kommer löpande att hämtas från ert affärssystem men ni behöver logga in i mina sidor och göra era val
                                            innan Factoringgruppen börjar hanteringen av dessa.
                                        </p>
                                        <p className="paragraph">
                                            Görs inget val inom 5 dagar kommer fakturorna att rensas från Mina sidor och ni får skicka dessa själva till er
                                            fakturamottagare. Vi kommer inte att rensa fakturorna i ert affärssystem.
                                        </p>
                                    </>
                                )}
                                <div>
                                    Vill du göra ändringar i eller har frågor om anslutningen till ert affärssystem och Factoringgruppen skall du kontakta oss
                                    på{' '}
                                    <a href="mailto:onboarding@fg.se" className="link">
                                        onboarding@fg.se
                                    </a>{' '}
                                    eller ringa{' '}
                                    <a href="tel:0317199920" className="link">
                                        031-719 99 20
                                    </a>
                                    <br />
                                    <br />
                                    <h3>Observera att Factoringgruppen inte stödjer fakturor som avser:</h3>
                                    <ul>
                                        <li>Kontantfaktura</li>
                                        <li>Förfallna fakturor</li>
                                        <li>Om dessa redan är skickade till er kund (enligt ert affärsystem)</li>
                                        <li>Fakturor med bilagor</li>
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <h1 className="title title--h1">Integration med ditt affärsssystem</h1>
                                <p className="paragraph">
                                    Via vårt samarbete med Strawbay kan Factoringgruppen hämta era fakturor direkt från ert affärssystem så snart dessa är
                                    bokförda.
                                </p>
                                <p className="paragraph">Detta innebär bland annat att ni slipper arbetet med att maila oss PDF-kopior av era fakturor.</p>
                                <p className="paragraph">I dagsläget stödjer vi Fortnox.</p>
                                {/* <p className="paragraph">Det första du behöver göra är att välja från vilket affärssystem fakturan skall skickas.</p> */}
                                <p className="paragraph">
                                    När du valt Fortnox skall du välja vilken slags tjänst du vill ha, antingen "Välj själv" eller "Autopilot". Välj sedan 
                                    från vilket datum vi ska hämta fakturor. Klicka sedan på "Starta onboarding i Strawbay"
                                </p>
                                <p className="paragraph">    
                                    Vill du ha mer information vänligen ring{' '}
                                    <a href="tel:0317199920" className="link">
                                        031-719 99 20
                                    </a>{' '}
                                    eller maila oss på <a href="mailto:onboarding@fg.se">onboarding@fg.se</a>
                                </p>
                                <p className="paragraph">Har du ett annat affärssystem, maila oss.</p>
                                Följande fakturor stödjs inte:
                                <ul>
                                        <li>Kontantfaktura</li>
                                        <li>Förfallna fakturor</li>
                                        <li>Om dessa redan är skickade till er kund (enligt ert affärsystem)</li>
                                        <li>Fakturor med bilagor</li>
                                </ul>
                                <h3 className="title title--h3">Affärssystem</h3>
                                
                                <div className="selection-list">
                                    {this.props.organisation.organisationNumber === '5561020115' || this.props.organisation.organisationNumber === '556102-0115' ||
                                    this.props.organisation.organisationNumber === '5590121736' || this.props.organisation.organisationNumber === '559012-1736' ? (
                                        <div
                                        className={`selection-content ${this.state.partner === 'VismaEAccounting' ? 'selected' : ''}`}
                                        onClick={this.selectIntegration.bind(this, 'VismaEAccounting')}
                                    >
                                        <div className="content">
                                            <img
                                                src="https://vismaspcs.se/globalassets/marknadsbilder/symboler/eekonomi-logomark.svg"
                                                alt="Visma eEkonomi"
                                                className="visma-logo"
                                            />
                                            <span>Visma eEkonomi</span>
                                        </div>
                                    </div>
                                    ) : (<br />)}
                                    
                                    <div
                                        className={`selection-content ${this.state.partner === 'Fortnox' ? 'selected' : ''}`}
                                        onClick={this.selectIntegration.bind(this, 'Fortnox')}
                                    >
                                        <div className="content">
                                            <img src="https://www.fortnox.se/fortnox-text-logo-white-svg.svg" alt="Fortnox" className="fortnox-logo" />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <h3 className="title title--h3">Välj hur fakturor ska hanteras</h3>
                                <div className="selection-list">
                                    <div
                                        className={`selection-content ${this.state.integrationMode === 'Manuell' ? 'selected' : ''}`}
                                        onClick={this.selectIntegrationMode.bind(this, 'Manuell')}
                                    >
                                        <div className="content">
                                            <div className="inner-content">
                                                <h4 className="title title--h4">Välj själv</h4>
                                                <div className="caption">
                                                    När du bokfört din faktura i ditt affärssystem så finns den här i mina sidor. Du väljer själv om den skall
                                                    finansieras eller bara bevakas. Du kan även ta bort de fakturor du vill hantera helt själv.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`selection-content ${this.state.integrationMode === 'Autopilot' ? 'selected' : ''}`}
                                        onClick={this.selectIntegrationMode.bind(this, 'Autopilot')}
                                    >
                                        <div className="content">
                                            <div className="inner-content">
                                                <h4 className="title title--h4">Autopilot</h4>
                                                <div className="caption">
                                                    Så snart din faktura är bokförd i ditt system kommer den hanteras av oss. Du behöver inte logga in på mina
                                                    sidor för ytterligare val, vi kommer försöka finansiera dessa per automatik.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <h3 className="title title--h3">Välj från vilket datum vi ska hämta fakturor</h3>
                                <div className="info-process">
                                    <DayPickerInput
                                            defaultDate={null}
                                            callBack={this.selectInvoicesDate.bind(this)}
                                            disabledBefore={new Date()}
                                        />
                                </div>
                                <div className="info-process">
                                    <div className="paragraph">
                                        Vi använder oss av Strawbays tjänster för överföring av data från ert affärsssystem till Factoringgruppen. För att sätta
                                        upp överföringen behöver du tillåta att Strawbay läser information från ert affärsssystem
                                    </div>
                                    
                                        <a href={this.getFormattedOnboardingUrl()} target={isWebView() ? '' : '_blank'} rel="noopener noreferrer">
                                            <button
                                                disabled={this.props.integration.onboardingLink.length === 0}
                                                className={`button button--right ${
                                                    this.props.integration.onboardingLink.length === 0 ? 'button--disabled' : ''
                                                }`}
                                            >
                                                <div className="onboarding-button">
                                                    Starta onboarding i Strawbay<Icon name="external" /> {this.props.integration.urlLoading ? <Spinner />: ''}
                                                </div>
                                            </button>
                                        </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        integration: state.integration,
        organisation: state.organisation,
    };
};

IntegrationPage = connect(mapStateToProps, {
    integrationHasCompleted,
    integrationInitiateOnboarding,
})(IntegrationPage);

export default IntegrationPage;
